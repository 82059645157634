import React from 'react'
import { Link } from 'gatsby'
import ReactSVG from 'react-svg'

export default class Navbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      status: '',
      content: '',
      navigation: [],
      isMenuOpen: false,
      isNavigationVisible: false,
    }
    this.activateMenu = this.activateMenu.bind(this)
    this.deactivateMenu = this.deactivateMenu.bind(this)
  }

  componentDidMount() {
    window.addEventListener('resize', this.deactivateMenu)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.deactivateMenu)
  }

  activateMenu() {
    this.setState((prevState) => ({
      isMenuOpen: !prevState.isMenuOpen,
    }))
  }

  deactivateMenu() {
    this.setState({
      isMenuOpen: false,
    })
  }

  render() {
    const activeClassName = 'nav-link--active'

    let btnClass =
      'btn btn-nav block md:hidden' +
      (this.state.isMenuOpen ? ' btn-nav--active' : '')
    let navClass =
      'mobile-nav' + (this.state.isMenuOpen ? ' mobile-nav--active' : ' ')

    return (
      <>
        <header className='header'>
          <nav className='navigation'>
            <div className='container'>
              <div className='navigation__inner py-1 flex items-center justify-start'>
                <Link className='brand' to='/'>
                  <ReactSVG src='/img/netball-logo.svg'></ReactSVG>
                </Link>
                <button className={btnClass} onClick={this.activateMenu}>
                  <span></span>
                  <span></span>
                  <span></span>
                </button>

                <div className='navigation__list ml-4 hidden md:block'>
                  <Link
                    to='/'
                    className='nav-link mr-6'
                    activeClassName={activeClassName}
                    partiallyActive>
                    Home
                  </Link>
                  <Link
                    to='/pricing'
                    className='nav-link mr-6'
                    activeClassName={activeClassName}>
                    Pricing
                  </Link>
                  <Link
                    to='/about'
                    className='nav-link mr-6'
                    activeClassName={activeClassName}>
                    How it works
                  </Link>
                  <Link
                    to='/faq'
                    className='nav-link mr-6'
                    activeClassName={activeClassName}>
                    FAQ
                  </Link>
                  <Link
                    to='/contact'
                    className='nav-link'
                    activeClassName={activeClassName}>
                    Contact
                  </Link>
                </div>
                <div className='ml-auto navigation__login hidden md:block'>
                  <a
                    href='https://app.selectrnetball.com.au/'
                    className='btn btn-outlined mr-4'>
                    Login
                  </a>
                  <a
                    href='https://app.selectrnetball.com.au/signup'
                    className='btn btn-primary'>
                    Sign up
                  </a>
                </div>
              </div>
            </div>
          </nav>
        </header>
        <div className={navClass}>
          <Link
            to='/'
            className='nav-link mb-6'
            activeClassName={activeClassName}
            partiallyActive>
            Home
          </Link>
          <Link
            to='/pricing'
            className='nav-link mb-6'
            activeClassName={activeClassName}>
            Pricing
          </Link>
          <Link
            to='/about'
            className='nav-link mb-6'
            activeClassName={activeClassName}>
            About
          </Link>
          <Link
            to='/contact'
            className='nav-link mb-6'
            activeClassName={activeClassName}>
            Contact
          </Link>
          <div className='flex space-x-4'>
            <a
              href='https://app.selectrnetball.com.au/'
              className='btn btn-outlined'>
              Login
            </a>
            <a
              href='https://app.selectrnetball.com.au/signup'
              className='btn btn-primary'>
              Sign up
            </a>
          </div>
        </div>
      </>
    )
  }
}
