const path = require('path')
const tailwind = require('tailwindcss')

module.exports = {
  siteMetadata: {
    baseTitle: 'Welcome',
    separator: '|',
    siteUrl: `localhost:8000`,
    title: 'Selectr',
    image: '',
    themeColor: '#fff',
    keyword: 'netball, selectr',
    description:
      'Selectr saves you time and effort by automating the netball team selection process. ',
  },
  plugins: [
    'gatsby-plugin-sharp',
    'gatsby-transformer-sharp',
    'gatsby-plugin-preact',
    'gatsby-plugin-webpack-bundle-analyser-v2',
    {
      resolve: 'gatsby-transformer-remark',
      options: {
        plugins: [
          {
            resolve: `gatsby-remark-relative-images`,
            options: {
              name: 'uploads',
            },
          },
          {
            resolve: `gatsby-remark-images`,
            options: {
              maxWidth: 590,
            },
          },
        ],
      },
    },
    {
      resolve: 'gatsby-plugin-google-tagmanager',
      options: {
        id: 'GTM-NBLZZ9X',
        includeInDevelopment: false,
        defaultDataLayer: { platform: 'gatsby' },
      },
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        path: `${__dirname}/src/img`,
        name: 'images',
      },
    },
    {
      // keep as first gatsby-source-filesystem plugin for gatsby image support
      resolve: 'gatsby-source-filesystem',
      options: {
        path: `${__dirname}/static/img`,
        name: 'uploads',
      },
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        path: `${__dirname}/content`,
        name: 'pages',
      },
    },
    'gatsby-plugin-react-helmet',
    {
      resolve: `gatsby-plugin-sass`,
      options: {
        postCssPlugins: [
          tailwind,
          require('./tailwind.config.js'), // Optional: Load custom Tailwind CSS configuration
        ],
      },
    },
    {
      resolve: `gatsby-plugin-purgecss`,
      options: {
        printRejected: true,
        develop: false, // Enable while using `gatsby develop`
        tailwind: true, // Enable tailwindcss support
        whitelist: [
          'section-content',
          'underlined',
          'h3',
          'h4',
          'h6',
          'fade-enter',
          'react-tabs__tab-panel--selected',
          'fade-exit',
          'fade-enter-active',
          'fade-exit-active',
        ],
        whitelistPatternsChildren: [
          /section-content$/,
          /input$/,
          /slick-dots$/,
          /textarea$/,
          /ul$/,
        ],
      },
    },
    {
      resolve: 'gatsby-plugin-root-import',
      options: {
        '@': path.join(__dirname, 'src'),
        '~': path.join(__dirname),
        styles: path.join(__dirname, 'src/styles'),
        img: path.join(__dirname, 'static/img'),
      },
    },
    {
      resolve: 'gatsby-plugin-netlify-cms',
      options: {
        modulePath: `${__dirname}/src/cms/cms.js`,
      },
    },
    'gatsby-transformer-ffmpeg',
    'gatsby-plugin-advanced-sitemap',
    'gatsby-plugin-netlify', // make sure to keep it last in the array
  ],
}
