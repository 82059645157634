import React from 'react'
import { Link } from 'gatsby'
import ReactSVG from 'react-svg'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className='footer'>
        <div className='footer__main'>
          <div className='container'>
            <div className='flex flex-wrap'>
              <div className='w-full md:w-4/12'>
                <div className='w-full md:w-8/12 mx-auto md:mx-0 text-center md:text-left mb-8 md:mb-0'>
                  <div className='footer__brand mb-5 mx-auto md:mx-0'>
                    <ReactSVG src='/img/netball-logo.svg'></ReactSVG>
                  </div>

                  <div className='text-gray'>
                    <p className='text-sm'>
                      In the spirit of reconciliation Selectr acknowledges the
                      Traditional Custodians of country throughout Australia and
                      their connections to land, sea and community. We pay our
                      respect to their Elders past and present and extend that
                      respect to all Aboriginal and Torres Strait Islander
                      peoples today.
                    </p>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-8/12 flex flex-wrap text-center md:text-left'>
                <div className='w-full sm:w-1/2 md:w-1/4 mb-8 md:mb-0'>
                  <h5 className='font-semibold text-base'>Navigation</h5>
                  <nav className='footer-nav'>
                    <Link to='/' className='footer-nav-link'>
                      Home
                    </Link>
                    <Link to='/pricing' className='footer-nav-link'>
                      Pricing
                    </Link>
                    <Link to='/about' className='footer-nav-link'>
                      About
                    </Link>
                  </nav>
                </div>
                <div className='w-full sm:w-1/2 md:w-1/4 mb-8 md:mb-0'>
                  <h5 className='font-semibold text-base'>Resources</h5>
                  <nav className='footer-nav'>
                    <Link to='/faq' className='footer-nav-link'>
                      FAQ
                    </Link>
                    <Link to='/terms' className='footer-nav-link'>
                      Terms & Conditions
                    </Link>
                    <Link to='/privacy-policy' className='footer-nav-link'>
                      Privacy Policy
                    </Link>
                  </nav>
                </div>
                <div className='w-full sm:w-1/2 md:w-1/4 mb-8 md:mb-0'>
                  <h5 className='font-semibold text-base'>Contact</h5>
                  <div className='text-gray mt-4'>
                    <Link to='/contact' className='footer-nav-link'>
                      Contact us
                    </Link>
                  </div>
                </div>
                <div className='w-full sm:w-1/2 md:w-1/4'>
                  <div className='flex justify-center md:justify-start'>
                    <a href='#' className='social-link'>
                      <ReactSVG src='/img/social/facebook.svg'></ReactSVG>
                    </a>
                    <a href='' className='social-link'>
                      <ReactSVG src='/img/social/instagram.svg'></ReactSVG>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='footer__sponsor'>
          <div className='container'>
            <div className='footer__sponsor-info'>Supported by</div>
            <img src='/img/ACT-Gov–CBRIN-ICON.jpg' alt='' />
          </div>
        </div>

        <div className='footer__copy'>
          <div className='container'>
            <div className='text-xs text-center text-gray'>
              Designed & Developed by{' '}
              <a
                href='https://cleancommit.io'
                className='text-gray'
                target='_blank'>
                Clean Commit
              </a>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
